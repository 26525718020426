import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import ContactForm from "../pages/contact/form";

import { motion, useAnimation } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";

export const PrivacyPolicyTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="alt-hero" id="about">
    <div className="container">
          <div className="why">
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.25,
                  }}
                >
                  Privacy Policy
                </motion.h3>
              )}
            </InView>
          </div>
          <div className="about">
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.25,
                  }}
                  className="smallertext"
                >
                  We have issued this Data Processing Notice in accordance with the General Data Protection Regulation (EU) 2016/679 (‘GDPR’) and any associated legislation e.g. the Data Protection, Privacy and Electronic Communications (Amendments etc) (EU Exit) Regulations 2019 and the 2018 UK Data Protection Act. In this Data Processing Notice, any references to GDPR also relate to associated legislation.
                  <br /><br />

​

​

​

This Data Processing Notice will outline:
<br /><br />

​

1. What do we do with your personal data?
2. What personal data do we collect?
3. How do we collect your personal data?
4. Why do we process your personal data?
5. Will I be receiving marketing emails, and can I opt out of this?
6.. How long will we store your personal data for?
7. What are your rights?
8. Will my personal data be used to make automated decisions?
9. How can I lodge a complaint?
10. Changes to this Data Processing Notice
11. Contact details of the Data Protection Officer
<br /><br />

​

​

1. What do we do with your personal data?
<br /><br />

 

Your personal data will be stored on our Bullhorn CRM system and can be accessed by all our recruitment consultants. Opal Digital are obliged to comply with GDPR when processing your personal data, provided that you reside in the European Union.
<br /><br />

Your personal data may also be shared with our client(s) for the purpose of introducing you and arranging interviews with them. We will always ask you for permission before introducing you to our client and we will be transparent on the exact details that will be forwarded.
<br /><br />

Furthermore, we may disclose your personal information to third parties in the event that we sell or buy any business or assets, in which case we will have to disclose your personal data to the prospective seller or buyer of such business or assets.
<br /><br />

We may also be under a duty to disclose or share your personal data in order to comply with any legal obligation, to defend our business against a legal claim, to enforce or apply our standard terms of business or other agreements or to protect the rights, property or safety of Opal Digital, our customers or other parties.
<br /><br />

 

​

2. What personal data do we collect?
<br /><br />

​

We collect the following personal data:
<br /><br />

⦁ Your full name
⦁ Your registered address
⦁ Your date of birth
⦁ Your email addresses
⦁ Your (mobile) telephone number
⦁ Your website
⦁ Professional profiles available in the public domain, e.g. LinkedIn, Twitter or Facebook
⦁ Your CV
⦁ Skillset (if not already covered in your CV)
⦁ Job preferences (if not already covered in your CV)
⦁ Current employer/client
⦁ Your right to work, when requested by our client
⦁ Current salary/employment conditions
⦁ Desired salary/employment conditions
⦁ Other documentation, if requested by our clients (e.g. references)
<br /><br />

 

3. How do we collect your personal data?
<br /><br />

​

We collect your personal data in the following ways:
<br /><br />

​

1. You apply for a job in response to a job ad.
2. You upload your CV on www.opaldigital.co.uk
3. You complete a ‘Contact us’ form or register with us on our website
4. Our recruitment consultants get in contact with you because they located your profile on a database/ job board you have subscribed to or registered your CV with
5. We have received your details from another third party. For example, a business contact, a client or professional advisors.
6. You applied for a job ad posted by Opal Digital on an external job board, e.g. TotalJobs or LinkedIn.
7. You have spoken with a recruitment consultant and you have provided us with your CV/application.
<br /><br />

When we receive your details, we save your application and information on our CRM system. You will receive a notification when this happens, and our recruitment consultant can confirm how they found your profile or CV. If this information is not provided, please let us know and we will clarify how we located your details.
<br /><br />

​

​

4. Why do we process your personal data?
<br /><br />

​

We collect your information to carry out our core business activities. We are a recruitment business and want to help you in finding the right job that matches your skillset.
<br /><br />

More specifically, we process your personal data for the following purposes:
<br /><br />

⦁ Introducing you to (potential) clients;
⦁ Keeping your personal data on file for current and future opportunities that may arise;
⦁ Evaluating your experience with Opal Digital and our recruitment process;
⦁ Verifying your identity and/ or the right to work.
<br /><br />

 

The following legal basis for processing your personal data applies if we have been in touch with you in the last three years:
<br /><br />


We will process your data as we are of the view that we have legitimate interests to do so.
<br /><br />

 

As a recruitment business, we introduce candidates to clients for permanent employment and temporary worker placements. We need to satisfy our client’s resourcing needs and therefore require an up-to-date database of candidate data. We will only use your personal data to introduce you to our client and do not share more data with our client than reasonably necessary. We usually process data that is already available online (e.g. a CV downloaded from a job board) and ask for additional information that is relevant to your application. Thus, to maintain, expand and develop our business we need to record the personal data of candidates who have been in touch with Opal Digital over the last three years. We have chosen this three-year period for the following reasons:
<br /><br />

​

(1) It is likely that there is a(n) ongoing relationship between you and Opal Digital.
(2) You may still be looking for opportunities
(3) The quality of your data still enables us to match your profile with a new career opportunity
(4) We are of the view that this timeframe reflects industry standards
<br /><br />

The following legal basis applies for processing your data if you are employed by one of our clients:
If you have been successfully hired by one of our clients as an employee or contractor, we have a legal obligation to keep some of your data on file. We may have to evidence any transactions to auditors or the authorities. We may also have to keep your personal data to defend ourselves against legal claims.
<br /><br />

​

​

5. Will I be receiving marketing emails, and can I opt-out of this?
<br /><br />

 

We may process your personal data for marketing purposes. This is optional, not compulsory.
<br /><br />

The legal basis for collecting and processing personal data for marketing purposes is ‘consent’ which means that we will only send you marketing communication if you ticked a box indicating that you are happy for us to send you marketing updates.
<br /><br />

If you have opted in to marketing updates, we may use your data to personalise your experience and provide you with relevant service announcements and updates. We will moreover be able to match your profile with relevant job opportunities. Our marketing team will analyse your profile and provide you with additional information which helps you to better position yourself in the job market, i.e. with invitations to networking events in your city, news and updates relevant for your area of expertise, or job alerts tailored to your preferences.
<br /><br />

You are entitled to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal. To opt-out of any data processing and/ or marketing communications from Opal Digital – please email info@opaldigital.co.uk to be full removed.
<br /><br />

​

​

6. How long will we store your data for?
<br /><br />

 

Your personal data will be stored in our system for three consecutive years. As long as you are working with our recruitment consultants on your next career step, we will keep your data on file so we are able to match your profile with new opportunities.
<br /><br />

Your data will be removed from our systems if

a)     we have not heard from you during this three- year period,

b)      you want your data to be removed
<br /><br />
 

​

7. What are your rights?
<br /><br />

​

You are entitled to various rights:
<br /><br />

• You can request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected. This means when working with our recruitment consultants, ensure that you notify us of any changes or update your information like email, phone number etc. when they change.
<br /><br />

• You can request the erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).
<br /><br />

• You can object to the processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.
<br /><br />

 

• You can request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example, if you want us to establish its accuracy or the reason for processing it.
<br /><br />

• You can request the transfer of your personal information to another party in certain formats, if practicable.
<br /><br />

​

​

8. Will my personal data be used to make automated decisions?
<br /><br />

 

We will not conduct any forms of automated processing of your personal data consisting of the use of personal data to evaluate certain personal aspects relating to you. We will not analyse or predict aspects concerning your performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements. Furthermore, we will not make decisions that are based solely on automated processing which produces legal effects or similarly significantly affects your rights.
<br /><br />

 

​

9. How can I lodge a complaint?
<br /><br />

 

If you feel that your rights have been violated, you are entitled to lodge a complaint with the supervisory authority of your country of residence. If you are based in the UK you can contact the Information Commissioner’s Office. Alternatively, you can seek a legal remedy. However, before escalating the case to the competent authorities, we strongly encourage you to contact us by emailing info@opaldigital.co.uk. We will then review your complaint at our earliest convenience.
<br /><br />

 

​

10. Changes to this Data Processing Notice
<br /><br />

 

Any changes we make to our Data Processing Notice in the future will be posted on this website and where appropriate (if we make any significant changes that may affect your rights as a data subject), notified to you by email. Please check back frequently to see any updates or changes to this Data Processing Notice.<br /><br />

12. Contact details of the Data Protection Officer
<br /><br />

 

If you have any questions, comments or requests regarding your personal data or this Data Processing Notice, please address them to:
<br /><br />

Nathan Abery - Director
nathan@weareopal.digital
<br /><br />
 

​

 


Cookies Policy
<br /><br />
 

Opal Digital uses cookies on its website. You can find out more about cookies and how to control them in the information below.
<br /><br />
 

What is a cookie?
<br /><br />
 

A cookie is a small text file that is delivered by a website server onto the computers of visitors to its website. Cookies can then be used by the website at a later time. Cookies are useful because they allow a website to recognize your device.
<br /><br />
 

Which cookies do we use?
<br /><br />
 

From time to time Opal Digital and other companies may set cookies on your browser through your usage of the Opal Digital website.
<br /><br />
See below the cookies that apply when visiting our website:
<br /><br />
​

The necessary cookies aim to enhance the user experience by identifying particular devices and thereby remembering your website preferences, browser settings and log-in details. These cookies are essential for the proper functioning of our website and expire either immediately or when the browsing session ends.
<br /><br />
​

You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling necessary cookies, however, may affect the functionality of and features of this website. Therefore it is recommended that you do not disable cookies.
<br /><br />
 

Our website also uses third party-cookies:
<br /><br />
​

Cookies Google Analytics: Google Analytics helps Opal Digital to measure how you as a user interact with our website content. As you navigate between web pages, Google Analytics provides website owners like Opal Digital with JavaScript tags (libraries) to record information about the pages you have seen, for example, the URL of the page. Google Analytics collects first-party cookies, data related to the device/browser, IP address and on-site/app activities to measure and report statistics about your interactions.
<br /><br />
 

If you do not accept the use of these cookies then please disable by changing your browser settings so that cookies from this website cannot be placed on your computer or mobile device.
<br /><br />
 

 

Changes to this Cookie Policy
<br /><br />
​

Any changes we make to our Data Processing Notice in the future will be posted on this website please check back frequently to see any updates or changes to our Cookie Policy.
<br /><br />
 

​

Contact detail for data queries
<br /><br />
​

Questions, comments, and requests regarding this Cookie Policy are welcomed and should be addressed to:<br /><br />

 

Nathan Abery – Director
<br /><br />
Contact: nathan@weareopal.digital
                </motion.p>
              )}
            </InView>
            
          </div>
        </div>
    </section>
  </div>
);

PrivacyPolicyTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ClientServices = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PrivacyPolicyTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ClientServices.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ClientServices;

export const pageQuery = graphql`
  query PrivacyPolicyTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
